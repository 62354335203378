// feature
import styled from 'styled-components'
import { breakpointUp } from '@/utils/breakpoints'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// components
import Layout from '@/components/Layout'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { LinkButton } from '@/components/LinkButton'
import { BreakPointHiddenBr } from '@/components/Utils'

const IndexPage = () => (
  <Layout marginBottom={0}>
    <Seo page="thanks" />
    <HeadingContainer>
      <HeadingLevel1>お問い合わせ</HeadingLevel1>
    </HeadingContainer>
    <LayoutContainer bgColor="attention">
      <Thanks>
        <Text>
          お問い合わせいただきありがとうございました。
          <BreakPointHiddenBr direction="down" breakpoint="sm" />
          お問い合わせを受け付けました。
          <br />
          折り返し、担当者よりご連絡いたしますので、
          <BreakPointHiddenBr direction="down" breakpoint="sm" />
          恐れ入りますが、しばらくお待ちください。
        </Text>
        <LinkButtonContainer>
          <LinkButton label="トップページへ戻る" link="/" color="theme" />
        </LinkButtonContainer>
      </Thanks>
    </LayoutContainer>
  </Layout>
)

const HeadingContainer = styled.div`
  text-align: center;
  padding-bottom: 4rem;
`

const Thanks = styled.div`
  padding: 8rem 0;
  max-width: 75rem;
  margin: auto;
`

const Text = styled.p`
  ${breakpointUp('md')} {
    text-align: center;
  }
`

const LinkButtonContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
`

export default IndexPage
